<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <b-button
                  :disabled="!$can('create', resource)"
                  :variant="!$can('create', resource) ? 'secondary' : 'primary'"
                  :to="{ name: `create-${route}` }"
                >
                  {{ $t("button.create") }}
                </b-button>
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
        >
          <template #cell(name)="data">
            {{ trans(data.item, "name", $i18n.locale) }}
          </template>
          <template #cell(itemType)="data">
            <span
              :class="{
                'text-primary': data.item.itemType == 1,
                'text-success': data.item.itemType == 2,
              }"
            >
              {{ $t(`itemType.${data.item.itemType}`) }}
            </span>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BAvatar, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import NColumnVisibility from "@/components/NColumnVisibility";

const ItemRepository = Repository.get("item");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NColumnVisibility,
    vSelect,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.item;
      },
      set(value) {
        this.$store.commit("column/SET_ITEM_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        itemCode: this.$route.query.itemCode || null,
        nameEn: this.$route.query.nameEn || null,
        nameKm: this.$route.query.nameKm || null,
        itemType: this.$route.query.itemType || [],
        isEnable: this.$route.query.isEnable || null,
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        itemCode: this.$route.query.itemCode || null,
        nameEn: this.$route.query.nameEn || null,
        nameKm: this.$route.query.nameKm || null,
        itemType: this.$route.query.itemType || [],
        isEnable: this.$route.query.isEnable || null,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      ItemRepository.index({
        ...this.query,
        searchFields: this.searchFields,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "item";
    const route = "item";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
